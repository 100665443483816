
import {defineComponent, onMounted, ref, watch} from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import AddUserModal from "@/components/modals/forms/AddUserModal.vue";
import EditUserModal from "@/components/modals/forms/EditUserModal.vue";
import {MenuComponent} from "@/assets/ts/components";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";

interface IUser {
  ID: number;
  name: string;
  email: string;
  role: string;
}

export default defineComponent({
  name: "users-listing",
  components: {
    Datatable,
    AddUserModal,
    EditUserModal,
  },
  setup() {
    const store = useStore();
    const checkedUsers = ref([]);
    const tableHeader = ref([
      /*{
        key: "checkbox",
      },*/
      {
        name: "User Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Email",
        key: "email",
        sortable: true,
      },
      {
        name: "Role",
        key: "role",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);
    const tableData = ref<Array<IUser>>([]);
    const initUsers = ref<Array<IUser>>([]);
    const isAdmin = ref<boolean>(false);
    const loading = ref<boolean>(false);

    const getUser = () => {
      store.dispatch(Actions.USER_PROFILE)
          .then(({data}) => {
            isAdmin.value = data.role == "admin"
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
    }

    const fetchData = () => {
      loading.value = true

      store.dispatch(Actions.USERS_LIST)
          .then(({data}) => {
            tableData.value = data
            loading.value = false
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
    }

    onMounted(() => {
      getUser()
      fetchData()
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Users Listing", ["Apps", "Users"]);
    });

    watch(() => tableData.value, first => {
      initUsers.value.splice(0, initUsers.value.length, ...first);
    });

    const selectedUser = ref<IUser>()

    const selectUser = item => {
      selectedUser.value = item
    }

    const deleteFewUsers = () => {
      checkedUsers.value.forEach((item) => {
        deleteUser(item);
      });
      checkedUsers.value.length = 0;
    };

    const deleteUser = id => {
      store.dispatch(Actions.USER_DELETE, id)
          .then(() => {
            for (let i = 0; i < tableData.value.length; i++) {
              if (tableData.value[i].ID === id) {
                tableData.value.splice(i, 1);
              }
            }
          })
          .catch(() => {
            Swal.fire({
              text: store.getters.getErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
    };

    const search = ref<string>("");
    const searchItems = () => {
      tableData.value.splice(0, tableData.value.length, ...initUsers.value);
      if (search.value !== "") {
        let results: Array<IUser> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].toLowerCase().indexOf(value.toLowerCase()) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    return {
      tableData,
      tableHeader,
      deleteUser,
      search,
      searchItems,
      checkedUsers,
      deleteFewUsers,
      selectUser,
      selectedUser,
      isAdmin,
      loading,
      fetchData
    };
  },
});
